import { ApiService } from './api.service';
import { TxInfo } from './models/txInfo';
import { Utils } from './utils';
declare global {
    interface Window {
        ApplePaySession: any;
        parentDomain: string;
    }
}

let theCurrentSession : any = null;

export default class CgApplePay {
    protected ApplePaySession = window.ApplePaySession;
    private utils: Utils = null;
    public eventListenerAlreadyExists = false;
    
    constructor(private debugState: boolean = false) {
        this.utils = new Utils(this.debugState);
        console.log("cg apple pay v1.1.4");
        this.utils.logit("eventListenerAlreadyExists=" + this.eventListenerAlreadyExists);
        if (!this.eventListenerAlreadyExists) {
            const s : string = (new Date()).toISOString().substring(11) + ': '; // a unique timestamp to make sure by watching the logs that only one instance exists
            this.utils.logit("parent: adding event listener " + s);
            window.addEventListener("message",
            (e) => {
                const action = e.data.action;
                this.utils.logit(s + 'event ' + action + ' from pps, event=');
                this.utils.logit(JSON.stringify(e));
                if (e.data.dataType !== 'applepay') { return; }
                if (action == 'isAlive') {
                    this.utils.logit(s + "received isAlive, sending Alive");
                    const alivePayload: any = {
                        dataType: 'applepay',
                        action: 'Alive'
                    }
                    this.returnToIframe(e, alivePayload)
                }
                if (action == 'startDeal') {
                    this.utils.logit(s + "received startDeal, will send submitDeal after doing init");
                    const txInfo:TxInfo = e.data.txInfo;
                    const domainName = window.parentDomain ? window.parentDomain : txInfo.merchantSiteDomain;
                    txInfo.merchantSiteDomain = domainName;

                    this.utils.logit(s + 'start deal from pps, txInfo=' + JSON.stringify(txInfo))
                    
                    this.init(txInfo, (token: any) => {
                        this.utils.logit(s + 'parent: init() callback called, sending submitDeal to iframe, token=');
                        this.utils.logit(s + JSON.stringify(token));
                        const submitDealPayload: any = {
                            dataType: 'applepay',
                            token: token,
                            action: 'submitDeal'
                        }
                        this.returnToIframe(e, submitDealPayload)
                    });
                }
                if (action == 'paymentSucceeded') {
                    this.utils.logit(s + 'parent: paymentSucceeded from pps');
                    this.sucStatus();
                }
                if (action == 'paymentFailure') {
                    this.utils.logit(s + 'parent: paymentFailure from pps');
                    this.failStatus()
                }
            }, false);
            this.eventListenerAlreadyExists = true;
        }
        else {
            this.utils.logit("parent: not adding event listener");
        }
    }
    public returnToIframe = (e: any, appleData: any) => e.source.postMessage(appleData, e.origin);

    
    public sucStatus(): string {
        this.utils.logit("parent: sucStatus started");
        return theCurrentSession.completePayment(this.ApplePaySession.STATUS_SUCCESS);
    }
    public failStatus(): string {
        this.utils.logit("parent: failStatus started");
        return theCurrentSession.completePayment(this.ApplePaySession.STATUS_FAILURE);
    }


    public async init(txInfo: any, cb?: Function) {
        this.utils.logit("parent: init started");
        if (!this.ApplePaySession) {
            console.error("parent: this browser does not support apple pay");
        } else {
            let canMakePayments = this.ApplePaySession.canMakePayments();
            if (canMakePayments) {
                this.utils.logit("parent: can make payments, calling startPayEvent");
                this.startPayEvent(cb, txInfo);
            } else {
                this.utils.logit("parent: canMakePayments==false");
                console.error("failed to generate apple pay process");
            }
        }
    }

    public async performValidation(txInfo:TxInfo): Promise<any> {
        try {
            this.utils.logit("parent: performValidation started");
            const apiService = new ApiService();
            var raw = JSON.stringify({
                "action": "performValidation",
                "displayName": txInfo.merchantDisplayName,
                "mid": txInfo.massEnrollmentMerchantId,
                "domainName": txInfo.merchantSiteDomain
            });
            const getMerchantVerification = await apiService.post(txInfo.serviceURL, raw);
            const token = await getMerchantVerification.json()
            this.utils.logit('parent: performValidation ending, returning token=');
            this.utils.logit(JSON.stringify(token));
            return token;
        } catch (e) {
            this.utils.logit(JSON.stringify(e));
            console.error("parent: absorbing exception in performValidation");
            return "fail to call verify merchant";
        }
    }

    public startPayEvent(cb: Function, txInfo: TxInfo) {
        try {
            this.utils.logit("parent: startPayEvent started, txInfo=");
            this.utils.logit(JSON.stringify(txInfo));
            let runningAmount: any = txInfo.amount;
            let runningPP: any = 0;
            let runningTotal = () => {
                let tempTotal: any = parseFloat(runningAmount) + parseFloat(runningPP);
                return parseFloat(tempTotal).toFixed(2);
            };
            let subTotalDescr = txInfo.merchantDisplayName;

            let paymentRequest = {
                currencyCode: txInfo.currency,
                countryCode: "IL",
                requiredBillingContactFields: ["email", "name", "phone"],
                lineItems: [{ label: subTotalDescr, amount: runningAmount }],
                total: {
                    label: txInfo.merchantDisplayName,
                    amount: runningTotal()
                },
                supportedNetworks: ["amex", "masterCard", "visa"],
                merchantCapabilities: ["supports3DS"]
            };

            this.utils.logit('parent: calling new this.ApplePaySession! paymentRequest=');
            this.utils.logit(JSON.stringify(paymentRequest));
            theCurrentSession = new window.ApplePaySession(1, paymentRequest);
            this.utils.logit('parent: this.ApplePaySession created! theCurrentSession=');
            this.utils.logit(theCurrentSession);

            // Merchant Validation
            theCurrentSession.onvalidatemerchant = async (event: any): Promise<any> => {
                try {
                    this.utils.logit('parent: start session.onvalidatemerchant, event=');
                    this.utils.logit(JSON.stringify(event));
                    let token = await this.performValidation(txInfo);
                    this.utils.logit('parent: returned from wait performValidation. token=');
                    this.utils.logit(JSON.stringify(token));
                    this.utils.logit('parent: calling completeMerchantValidation with token');
                    theCurrentSession.completeMerchantValidation(token);
                } catch (e) {
                    console.error("parent: absorbing exception in onvalidatemerchant, e=");
                    this.utils.logit(JSON.stringify(e));
                    return "fail to verify merchant";
                }
            };

            theCurrentSession.onpaymentmethodselected = (event: any) => {
                this.utils.logit("parent: starting session.onpaymentmethodselected, event=");
                this.utils.logit(JSON.stringify(event));

                let newTotal = {
                    type: "final",
                    label: txInfo.merchantDisplayName,
                    amount: runningTotal()
                };
                let newLineItems = [
                    { type: "final", label: subTotalDescr, amount: runningAmount }
                ];
                this.utils.logit("parent: calling completePaymentMethodSelection with newTotal= and newLineItems=");
                this.utils.logit(newTotal);
                this.utils.logit(newLineItems);
                theCurrentSession.completePaymentMethodSelection(newTotal, newLineItems);
            };

            /**
             @param status - reffer to the apple pay session status
             @param validToken - reffer to the transaction details
             */
             theCurrentSession.onpaymentauthorized = async (event: any) => {
                this.utils.logit("parent: starting session.onpaymentauthorized, event=");
                this.utils.logit(JSON.stringify(event));
                this.utils.logit('parent: NB: This is the first stage when you get the *full shipping address* of the customer, in the event.payment.shippingContact object');
                this.utils.logit(event.payment.shippingContact);
                let validToken = event.payment.token;
                this.utils.logit('parent: validToken=');
                this.utils.logit(JSON.stringify(validToken))
                try {
                    // validToken = await sendPaymentToken(event.payment.token);
                    this.utils.logit('parent: calling callback with valid token')
                    cb(validToken)
                } catch (error) {
                    console.error(JSON.stringify(error))
                    cb(error)
                }
            };


            theCurrentSession.oncancel = (event: any) => {
                console.log("parent: starting session.cancel, event=");
                console.log(JSON.stringify(event));
            };

            this.utils.logit('parent: calling theCurrentSession.begin()');
            theCurrentSession.begin();

            return theCurrentSession;
        } catch (error) {
            console.error("parent: absorbing unhandled exception in startPayEvent, error=");
            console.warn(error);
        }
    }

}

module.exports = CgApplePay;
